import React, { useState, useEffect, useContext } from 'react'
import './style.css'
import logo from '../Assets/prorec_vit.png'
import { Link } from "react-router-dom";
import { HeaderContext } from '../helper/Context';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
// Materail
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// Icons
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';
// Components
import AsideList from './AsideList'
const Aside = ({ active, mobile, tablet }) => {

    const navigate = useNavigate();
    const cookies = new Cookies();

    const { asideActive, setAsideActive } = useContext(HeaderContext)


    useEffect(() => {
        setAsideActive(active)
    }, [active])


    const [meny, setMeny] = useState(1);
    const handleChange = (event) => {
        setMeny(event.target.value);
    };

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        if (tablet || mobile) {
            setAsideActive(false)
        }
    };

    useEffect(() => {
        if (window.location.pathname.indexOf("/category") !== -1) {
            setSelectedIndex(5)
        } else if (window.location.pathname.indexOf("/account") !== -1) {
            setSelectedIndex(1)
        }


    }, [])

    const logout = () => {
        cookies.remove('jwt', { path: '/' });
        navigate("/login");
    }

    const asideToggle = () => {
        setAsideActive(false)
    }
    const renderMenu = () => {
        if (meny === 1) {
            return (
                <Box sx={{ width: '100%', maxWidth: 320, bgcolor: '#333' }} >

                    <List component="nav">
                        <Link to="/category">
                            <ListItemButton
                                selected={selectedIndex === 5}
                                onClick={(event) => handleListItemClick(event, 5)}
                            >
                                <ListItemIcon>
                                    <StoreIcon />
                                </ListItemIcon>
                                <ListItemText primary="Kategorier" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <List component="nav">
                        <Link to="/account">
                            <ListItemButton
                                selected={selectedIndex === 1}
                                onClick={(event) => handleListItemClick(event, 1)}
                            >
                                <ListItemIcon>
                                    <StoreIcon />
                                </ListItemIcon>
                                <ListItemText primary="Konton" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <List component="nav">
                        <Link to="/product">
                            <ListItemButton
                                selected={selectedIndex === 2}
                                onClick={(event) => handleListItemClick(event, 2)}
                            >
                                <ListItemIcon>
                                    <InventoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Produkter" />
                            </ListItemButton>
                        </Link>
                    </List>
                </Box>
            )
        }
    }

    return (
        <div className={asideActive ? 'aside-container active' : 'aside-container'}>
            <div className={asideActive ? 'aside active' : 'aside'}>
                <div className='aside-header'>
                    <div className='logo'>
                        <img src={logo} alt="logo" />
                    </div>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">meny</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={meny}
                            label="meny"
                            onChange={handleChange}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "center",
                                    horizontal: "center"
                                },
                                transformOrigin: {
                                    vertical: "center",
                                    horizontal: "center"
                                }
                            }}
                        >
                            <MenuItem value={1}>Webbshop</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='aside-body'>
                    <div className='aside-menu'>
                        {/* {renderMenu()} */}
                        <AsideList />
                    </div>
                    <div className='aside-bottom'>
                        <Button variant="contained" onClick={logout} fullWidth color="error" style={{ marginBottom: 4 }} startIcon={<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z" fill="#fff"></path></svg>}>Logga ut</Button>
                        <p style={{fontSize: '13px'}}>Copyright © 2017 - {new Date().getFullYear()} Prorec</p>
                        <p style={{fontSize: '13px'}}>v 2.2.0</p>

                    </div>
                </div>
            </div>

            {tablet ? <div className={asideActive ? 'overlay active' : 'overlay'} onClick={asideToggle}></div> : null}

        </div>
    )
}

export default Aside