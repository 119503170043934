import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../helper/Context';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
// Material
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';


const Dashboard = ({ authorized }) => {

    const navigate = useNavigate();

    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)

    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle("Dashboard")
        setHeaderLinkTo('')
        document.title = 'Prorec Webshop | Dashboard';
    }, [setHeaderTitle, setHeaderLinkTo, authorized])


    useEffect(() => {
        if (authorized) {
            loadProduct()
        }
    }, [authorized])

    const [products, setProducts] = useState([])
    const [productLoad, setProductLoad] = useState(false)



    const loadProduct = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            console.log(response.data)
            setProducts(response.data)
            setProductLoad(true)
        })
    }
    return (

        <div className='padding'>
            {productLoad ?

                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Card variant="outlined" style={{ backgroundColor: '#F2F2F2', boxShadow: '0 10px 10px rgba(0,0,0,.161)', border: "none", height: '100px', padding: '16px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <p style={{ fontSize: '34px' }}>{products.filter(row => parseInt(row.status) === 1).length}</p>
                            <p style={{ fontWeight: 'bold' }}>Accepterad</p>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card variant="outlined" style={{ backgroundColor: '#F2F2F2', boxShadow: '0 10px 10px rgba(0,0,0,.161)', border: "none", height: '100px', padding: '16px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <p style={{ fontSize: '34px' }}>{products.filter(row => parseInt(row.status) === 0).length}</p>
                            <p style={{ fontWeight: 'bold' }}>Obehandlad</p>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card variant="outlined" style={{ backgroundColor: '#F2F2F2', boxShadow: '0 10px 10px rgba(0,0,0,.161)', border: "none", height: '100px', padding: '16px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <p style={{ fontSize: '34px' }}>{products.filter(row => parseInt(row.status) === 2).length}</p>
                            <p style={{ fontWeight: 'bold' }}>Avvisade</p>
                        </Card>
                    </Grid>

                </Grid>
                :
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Skeleton variant="rounded" width={'100%'} height={100} style={{borderRadius: '4px'}}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Skeleton variant="rounded" width={'100%'} height={100} style={{borderRadius: '4px'}}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Skeleton variant="rounded" width={'100%'} height={100} style={{borderRadius: '4px'}}/>
                    </Grid>
                </Grid>

            }

        </div>
    )
}

export default Dashboard