import React from 'react'
import './style.css'
const Main = ({children, active}) => {
    return (
        <div className={active ? 'main' : 'main active'}>
            {children}
        </div>
    )
}

export default Main