import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../../helper/Context';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useParams } from 'react-router-dom'
import Alert from '@mui/material/Alert';
// Material
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// Icons
import SaveIcon from '@mui/icons-material/Save';
const SubCategoryAdd = ({ wh, authorized }) => {

    const navigate = useNavigate();

    const { iid } = useParams()
    let formData = new FormData();

    // Header config
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)
    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle("Lägg till under kategori ...")
        document.title = 'Hästgruppen | Lägg till under kategori ...';
        setHeaderLinkTo(`/category/${iid}`)
        loadCategory()
    }, [setHeaderTitle, setHeaderLinkTo, authorized])

    const [subCategoryName, setSubCategoryName] = useState("")
    const [alert, setAlert] = useState("")
    const [dAlert, setDAlert] = useState("")

    const loadCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_category.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            const thisRow = response.data.filter(row => row.category_ID === parseInt(iid) || row.category_ID === iid)
            setHeaderTitle(`Lägg till underkategori ${thisRow[0].name}`)
            document.title = `Hästgruppen | Lägg till underkategori ${thisRow[0].name}`;
        })
    }

    const [type, setType] = useState("1")
    const [viewInMain, setViewInMain] = useState("1")
    const [viewPlace, setViewPlace] = useState("1")
    const [filtered, setFiltered] = useState("0")
    const [filterType, setFilterType] = useState("1")

    const add = () => {
        formData.append('name', subCategoryName)
        formData.append('category', iid)
        formData.append('subCategoryType', type)

        formData.append('viewInMain', viewInMain)
        formData.append('viewPlace', viewPlace)
        formData.append('filtered', filtered)
        formData.append('filterType', filterType)



        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/category/api_post_sub_category.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            console.log(response.data)
            setSubCategoryName("")
            if (response.data === 1 || response.data === "1") {
                setAlert("Underkategori har lagt")
            } else {
                setDAlert("Något fel händer, kontakta support tack")
            }

        }).catch(function (response) {
            console.log(response);
        });
    }

    return (
        <Box className='padding fading'>
            <TextField value={subCategoryName} label="Namn" variant="outlined" fullWidth onChange={(e) => setSubCategoryName(e.target.value)} />
            <div className='d-mb'></div>
            <FormControl fullWidth>
                <InputLabel >Typ</InputLabel>
                <Select
                    value={type}
                    label="Typ"
                    onChange={(e) => setType(e.target.value)}
                >
                    <MenuItem value={"1"}>Textfield</MenuItem>
                    <MenuItem value={"2"}>DropDown</MenuItem>
                </Select>
            </FormControl>
            <div className='d-mb'></div>
            <FormControl fullWidth>
                <InputLabel >Visning i kort</InputLabel>
                <Select
                    value={viewInMain}
                    label="Visning i kort"
                    onChange={(e) => setViewInMain(e.target.value)}
                >
                    <MenuItem value={"1"}>Ja</MenuItem>
                    <MenuItem value={"2"}>Nej</MenuItem>
                </Select>
            </FormControl>
            <div className='d-mb'></div>
            <FormControl fullWidth>
                <InputLabel >Visning plats</InputLabel>
                <Select
                    value={viewPlace}
                    label="Visning plats"
                    onChange={(e) => setViewPlace(e.target.value)}
                >
                    <MenuItem value={"1"}>Top</MenuItem>
                    <MenuItem value={"2"}>Bot</MenuItem>
                </Select>
            </FormControl>
            <div className='d-mb'></div>
            <FormControl fullWidth>
                <InputLabel >Filtera</InputLabel>
                <Select
                    value={filtered}
                    label="Filtera"
                    onChange={(e) => setFiltered(e.target.value)}
                >
                    <MenuItem value={"0"}>Nej</MenuItem>
                    <MenuItem value={"1"}>Ja</MenuItem>
                </Select>
            </FormControl>
            <div className='d-mb'></div>
            <FormControl fullWidth>
                <InputLabel >Filtera typ</InputLabel>
                <Select
                    value={filterType}
                    label="Filtera typ"
                    onChange={(e) => setFilterType(e.target.value)}
                >
                    <MenuItem value={"1"}>DropDown</MenuItem>
                    <MenuItem value={"2"}>Range</MenuItem>
                </Select>
            </FormControl>
            <Fab color={'primary'} onClick={add} className='float-action-button'>
                <SaveIcon />
            </Fab>
            {alert ? <Alert style={{ position: 'absolute', bottom: 40, left: '50%' }} severity="success">{alert}</Alert> : <></>}
            {dAlert ? <Alert severity="error">{dAlert}</Alert> : <></>}

        </Box>
    )
}

export default SubCategoryAdd