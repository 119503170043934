import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../helper/Context';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';

import axios from 'axios';

// Material
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import Fab from '@mui/material/Fab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
// Icons
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
const ProductView = ({ wh, authorized }) => {

    const { iid } = useParams()
    const navigate = useNavigate();

    // Header config
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)
    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }

        setHeaderTitle("Annons")
        document.title = 'Hästgruppen | Annons';
        setHeaderLinkTo('/announces')
        loadData()
    }, [setHeaderTitle, setHeaderLinkTo, authorized])

    const [dataList, setDataList] = useState([])
    const [userDataList, setUserDataList] = useState([])
    const [loadedImages, setLoadedImages] = useState([])

    const [load, setLoad] = useState(false)



    const loadData = () => {
        setLoad(false)
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product-link.php`, {
            params: {
                link: iid
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setLoadedImages([])

            setDataList(response.data[0])
            console.log(response.data[0])
            setLoadedImages(loadedImages => [...loadedImages, {
                link: response.data[0].image,
                name: "primary",
            }]);

            if (response.data[0].targeting) {
                loadOrientation(response.data[0].targeting)
            }
            if (response.data[0].race) {
                loadRace(response.data[0].race)
            }
            if (response.data[0].gender) {
                loadGender(response.data[0].gender)
            }
            if (response.data[0].color) {
                loadColor(response.data[0].color)
            }
            if (response.data[0].category) {
                loadServiceCategory(response.data[0].category)
            }
            if (response.data[0].equip_cat) {
                loadEquipmentCategory(response.data[0].equip_cat)
            }
            if (response.data[0].equip_subcat) {
                loadEquipmentSubCategory(response.data[0].equip_subcat, response.data[0].equip_cat)
            }

            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product-image.php`, {
                params: {
                    id: response.data[0].product_ID
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                for (let i = 0; i < response.data.length; i++) {
                    setLoadedImages(loadedImages => [...loadedImages, {
                        link: response.data[i].link,
                        name: response.data[i].name,
                        status: 'inactive'
                    }]);
                }
            })

            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/users/api-get-one-user.php`, {
                params: {
                    id: response.data[0].user_ID
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                setUserDataList(response.data[0])
            })
            setLoad(true)
        })
    }

    const renderStatus = (status) => {
        if (status === 1) {
            return (
                <p>Acceptarad</p>
            )
        } else if (status === 2) {
            return (
                <p>Avvisad</p>
            )
        }
    }


    const [genderName, setGenderName] = useState("")
    const [raceName, setRaceName] = useState("")
    const [targetingName, setTargetingName] = useState("")
    const [colorNamn, setColoreName] = useState("")
    let years = new Date(new Date() - new Date(dataList.age)).getFullYear() - 1970;
    const [serviceCategory, serServiceCategory] = useState("")
    const [equipmentCategory, serEquipmentCategory] = useState("")
    const [equipmentSubCategory, serEquipmentSubCategory] = useState("")

    const loadOrientation = (targeting) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 3
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setTargetingName(response.data.filter(row => row.sub_sub_category_ID === targeting)[0].name)

        })
    }

    const loadGender = (gender) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 1
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setGenderName(response.data.filter(row => row.sub_sub_category_ID === gender)[0].name)

        })
    }

    const loadRace = (race) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 2
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setRaceName(response.data.filter(row => row.sub_sub_category_ID === race)[0].name)
        })
    }

    const loadColor = (color) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category_by_cat.php`, {
            params: {
                sid: 6
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setColoreName(response.data.filter(row => row.sub_sub_category_ID === color)[0].name)

        })
    }


    const loadServiceCategory = (category) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: 20
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            console.log(response.data)
            serServiceCategory(response.data.filter(row => row.sub_category_ID === category)[0].name)
        })
    }

    const loadEquipmentCategory = (equipmentCat) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: 19
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            serEquipmentCategory(response.data.filter(row => row.sub_category_ID === equipmentCat)[0].name)
        })
    }


    const loadEquipmentSubCategory = (equipmentSubCat, ssid) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category.php`, {
            params: {
                id: 19,
                sid: ssid
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            serEquipmentSubCategory(response.data.filter(row => row.sub_sub_category_ID === equipmentSubCat)[0].name)
        })
    }





    const acceptAnnounce = () => {
        let id = dataList.product_ID
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-accept-product.php`, {
            params: {
                id: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            window.location.reload();
        })
    }

    const rejectAnnounce = () => {
        let id = dataList.product_ID
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-reject-porduct.php`, {
            params: {
                id: id
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            window.location.reload();
        })
    }






    return (
        <Box sx={{ flexGrow: 1, height: wh - 64, width: '100%', display: 'flex', flexDirection: 'column' }}>
            {load ?
                <Grid container sx={{ height: '100%' }}>
                    <Grid item xs={8}>

                        <Box sx={{ height: '100%', borderRight: '1px solid #f2f2f2', marginRight: 2 }} className='padding'>
                            <Grid container spacing={2} >
                                {dataList.image ?
                                    loadedImages.map((val, i) => {
                                        return (
                                            <Grid item xs={2} key={i} >
                                                <img src={`${process.env.REACT_APP_SERVER_LINK}/upload/${val.link}`} alt={val.name} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            </Grid>
                                        )
                                    })
                                    :
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', border: '1px solid black', borderRadius: '4px', padding: '16px 16px 16px 16px', boxSizing: 'border-box'}}>
                                            <ImageNotSupportedIcon />
                                            <p>Ingen bild</p>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                            <div className='d-mb'></div>
                            <h1>{dataList.title}</h1>
                            {dataList.hight ? <p>Mankhöjd: {dataList.hight} cm</p> : null}
                            {genderName ? <p>Kön: {genderName}</p> : null}
                            {dataList.age ? <p>Ålder: {years} år</p> : null}
                            {dataList.originCountry ? <p>Ursprungsland: {dataList.originCountry}</p> : null}
                            {targetingName ? <p>Inriktning: {targetingName}</p> : null}
                            {colorNamn ? <p>Färg: {colorNamn}</p> : null}
                            {raceName ? <p>Ras: {raceName}</p> : null}
                            {dataList.name ? <p>Namn: {dataList.name}</p> : null}

                            {serviceCategory ? <p>kategori: {serviceCategory}</p> : null}

                            {equipmentCategory ? <p>Kategori: {equipmentCategory}</p> : null}
                            {equipmentSubCategory ? <p>Underkategori: {equipmentSubCategory}</p> : null}
                            {dataList.equip_condition ? <p>Skick: {dataList.equip_condition === "0" ? "Oanvänd" : dataList.equip_condition === "1" ? "Nyskick" : dataList.equip_condition === "2" ? "Begagnad" : dataList.equip_condition === "3" ? "Sliten" : ''}</p> : ''}
                            {dataList.make ? <p>Märke: {dataList.make}</p> : null}
                            {dataList.model ? <p>Modell: {dataList.model}</p> : null}
                            {dataList.totalWeight ? <p>Totalvikt: {dataList.totalWeight} kg</p> : null}
                            {dataList.loadWeight ? <p>Lastvikt: {dataList.loadWeight} kg</p> : null}

                        </Box>

                    </Grid>
                    <Grid item xs={4} style={{ lineHeight: '24px' }}>
                        <h2>Användarinformation</h2>
                        <p>{userDataList.user_email}</p>
                        <p>{userDataList.fname} {userDataList.lname}</p>
                        <p>{userDataList.phonenumber}</p>
                        <p>{userDataList.address}</p>
                        <p>{userDataList.city}, {userDataList.zipcode}</p>
                        <p>{userDataList.lan}</p>
                        <p>{userDataList.country}</p>
                        <p>{parseInt(userDataList.user_rule) === 52 ? 'Admin' : 'Användare'}</p>

                        {parseInt(dataList.status) === 0 ?
                            <Box sx={{ mt: 2 }}>
                                <h3 >Produkt Status</h3>
                                <Grid container spacing={2} sx={{ mt: 1 }}>
                                    <Grid item xs={6}>
                                        <Button variant="contained" fullWidth onClick={() => acceptAnnounce()}>Acceptera</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" fullWidth color="error" onClick={() => rejectAnnounce()}>Avvisa</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            :
                            <Box sx={{ mt: 2 }}>
                                <h3 >Produkt Status</h3>
                                {renderStatus(parseInt(dataList.status))}
                            </Box>
                        }

                    </Grid>
                </Grid>
                :
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            }
        </Box>
    )
}

export default ProductView