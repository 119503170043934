import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../helper/Context';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import validator from 'validator';
// Components
import Card from '../../Components/Card';
// Material
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';

// Icons
import SaveIcon from '@mui/icons-material/Save';

const AccountAdd = ({ id, authorized }) => {

    const navigate = useNavigate();
    let formData = new FormData();
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)

    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [email, setemail] = useState('')
    const [password, setPassword] = useState('')
    const [phonenumber, setPhonenumber] = useState('')
    const [address, setAddress] = useState('')
    const [zipcode, setZipcod] = useState('')
    const [city, setCity] = useState('')
    const [lan, setLan] = useState('')
    const [country, setCountry] = useState('')
    const [rule, setRule] = useState('1')

    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle("Kategori")
        setHeaderLinkTo('/account')
        document.title = 'Prorec Webshop | Nutt konto';
    }, [setHeaderTitle, setHeaderLinkTo, authorized])

    const register = () => {
        setErrorMessage("")
        if (!fname) {
            setErrorMessage("Förnamn måste inte vara tomt")
            return
        }
        if (!lname) {
            setErrorMessage("Efternamn måste inte vara tomt")
            return
        }
        if (!validator.isEmail(email)) {
            setErrorMessage("E-post är fel")
            return
        }
        if (password.length < 6 || password.length > 16) {
            setErrorMessage("Lösenord måste vara mellan 6 och 16 tecken")
            return
        }
        formData.append('fname', fname)
        formData.append('lname', lname)
        formData.append('email', email)
        formData.append('password', password)
        formData.append('phonenumber', phonenumber)
        formData.append('address', address)
        formData.append('city', city)

        formData.append('zipcode', zipcode)
        formData.append('lan', lan)
        formData.append('country', country)
        formData.append('rule', rule)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/users/api-post-users.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            console.log(response.data)
            if (response.data === 5 || response.data === '5') {
                setErrorMessage('Den e-post är redan finns')
            } else {
                navigate('/account')
            }
        }).catch(function (response) {
            console.log(response);
        });
    }

    return (
        <div>
            <div className="padding">
                <Card
                    title="Sakapa nytt konto"
                >
                    <div className="padding">
                        <TextField required label="Förenamn" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setFname(e.target.value)} />
                        <TextField required label="Efternamn" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setLname(e.target.value)} />
                        <TextField required label="E-post" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setemail(e.target.value)} />
                        <TextField required label="Lösenord" type="password" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setPassword(e.target.value)} />
                        <FormControl required style={{ marginBottom: 24 }} fullWidth>
                            <InputLabel >Användare Roll</InputLabel>
                            <Select
                                value={rule}
                                label="Användare Roll"
                                onChange={(e) => setRule(e.target.value)}
                            >
                                <MenuItem value={1}>Användare</MenuItem>
                                <MenuItem value={52}>supperuser admin</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="Telefonnummer" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setPhonenumber(e.target.value)} />
                        <TextField label="Adress" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setAddress(e.target.value)} />
                        <TextField label="Postnummer" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setZipcod(e.target.value)} />
                        <TextField label="Stad" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setCity(e.target.value)} />
                        <TextField label="Län" variant="outlined" fullWidth style={{ marginBottom: 24 }} onChange={(e) => setLan(e.target.value)} />
                        <TextField label="Land" variant="outlined" fullWidth onChange={(e) => setCountry(e.target.value)} />

                    </div>
                </Card>

            </div>

            <Fab color="secondary" aria-label="add" className='float-action-button' onClick={register}>
                <SaveIcon />
            </Fab>

            {errorMessage ? <Alert variant="filled" slyle={{ zIndex: 500 }} severity="error"> {errorMessage} </Alert> : null}


        </div>
    )
}

export default AccountAdd