import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../../helper/Context';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useParams } from 'react-router-dom'
import Alert from '@mui/material/Alert';
// Material
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
// Icons
import SaveIcon from '@mui/icons-material/Save';

const SubSubCategoryAdd = ({ wh, authorized }) => {

    const navigate = useNavigate();

    const { iid, siid } = useParams()
    let formData = new FormData();

    // Header config
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)
    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle("Lägg till under underkategori ...")
        document.title = 'Hästgruppen | Lägg till under underkategori ...';
        setHeaderLinkTo(`/category/sub/${iid}/${siid}`)
        loadSubCategory()
    }, [setHeaderTitle, setHeaderLinkTo, authorized])

    const [subCategoryName, setSubCategoryName] = useState("")
    const [alert, setAlert] = useState("")
    const [dAlert, setDAlert] = useState("")

    const loadSubCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: iid
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            const thisRow = response.data.filter(row => row.sub_category_ID === parseInt(siid) || row.sub_category_ID === siid)
            setHeaderTitle(`Lägg till under underkategori ${thisRow[0].name}`)
            document.title = `Hästgruppen | Lägg till under underkategori ${thisRow[0].name}`;
        })
    }


    const add = () => {
        formData.append('name', subCategoryName)
        formData.append('category', iid)
        formData.append('subcategory', siid)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/category/api_post_sub_sub_category.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            setSubCategoryName("")
            if (response.data === 1 || response.data === "1") {
                setAlert("Underkategori har lagt")
            } else {
                setDAlert("Något fel händer, kontakta support tack")
            }

        }).catch(function (response) {
            console.log(response);
        });
    }

    return (
        <Box className='padding'>
            <TextField value={subCategoryName} label="Namn" variant="outlined" fullWidth onChange={(e) => setSubCategoryName(e.target.value)} />
            <Fab color={'primary'} onClick={add} className='float-action-button'>
                <SaveIcon />
            </Fab>
            {alert ? <Alert style={{ position: 'absolute', bottom: 40, left: '50%' }} severity="success">{alert}</Alert> : <></>}
            {dAlert ? <Alert severity="error">{dAlert}</Alert> : <></>}

        </Box>
    )
}

export default SubSubCategoryAdd