import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../helper/Context';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
// Material
import Box from '@mui/material/Box';

import Fab from '@mui/material/Fab';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';

// icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
const Account = ({ wh, authorized }) => {

    const navigate = useNavigate();

    const { setHeaderTitle, setHeaderLinkTo } = useContext(HeaderContext)

    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle("Konton")
        setHeaderLinkTo('')
        document.title = 'Prorec Webshop | konto';
    }, [setHeaderTitle, setHeaderLinkTo, authorized])

    useEffect(() => {
        if (authorized) {
            loadAccount()
        }
    }, [authorized])


    const [accountsList, setAccountsList] = useState([])
    const [accountsListLoad, setAccountsListLoad] = useState(false)

    const loadAccount = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/users/api-get-users.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setAccountsList(response.data)
            setAccountsListLoad(true)
        })
    }

    const columns = [
        {
            field: 'user_email',
            headerName: 'E-post',
            flex: 1,
            editable: false,
        },

        {
            field: 'user_rule',
            headerName: 'Roll',
            renderCell: (cellValues) => {
                if (parseInt(cellValues.row.user_rule) === 52) {
                    return 'Admin'
                } else if (parseInt(cellValues.row.user_rule) === 62) {
                    return 'Admin'
                } else if (parseInt(cellValues.row.user_rule) === 1) {
                    return 'Användare'
                } else {
                    return 'Error'
                }
            },
            flex: 1,
            editable: false,
        },
        {
            field: 'handle',
            renderCell: (cellValues) => {
                return (
                    <div>
                        <Link to={`/account/edit/${cellValues.row.user_ID}`}>
                            <IconButton aria-label="edit" >
                                <EditIcon />
                            </IconButton>
                        </Link>
                        <IconButton aria-label="delete" >
                            <DeleteIcon />
                        </IconButton>
                    </div>

                )
            },
            headerName: '',
            type: 'number',
            flex: 1,
            editable: false,
        }
    ];

    return (
        <Box sx={{ height: wh - 64, width: '100%', display: 'flex', flexDirection: 'column' }}>

            {accountsListLoad ?
                <DataGrid
                    rows={accountsList}
                    columns={columns}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                    disableColumnMenu
                    disableCellFocusOutline
                    getRowId={(row) => row.user_ID}
                    sx={{
                        flexFlow: 1,
                        border: 'none',
                        "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                            display: "none"
                        }
                    }}
                />
                : <Box sx={{ width: '100%' }}> <LinearProgress color='secondary' /></Box>}
            <Link to="/account/add">
                <Fab color="secondary" aria-label="add" className='float-action-button'>
                    <AddIcon />
                </Fab>
            </Link>
        </Box>
    )
}

export default Account