import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../helper/Context';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
// Components
import Card from '../../Components/Card';
// Material

import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
// Icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddIcon from '@mui/icons-material/Add';

const Settings = ({ authorized }) => {

    const navigate = useNavigate();
    const { setHeaderTitle, setHeaderLinkTo } = useContext(HeaderContext)

    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle("Inställningar")
        setHeaderLinkTo('')
        document.title = 'Prorec Webshop | Inställningar';
    }, [setHeaderTitle, setHeaderLinkTo, authorized])

    const [facebook, setFacebook] = useState("")
    const [instagram, setInstagram] = useState("")
    const [headTags, setHeadTags] = useState("")

    useEffect(() => {
        if (authorized) {
            loadSocialMedia()
        }
    }, [authorized])

    const [socialMediaListLoad, setSocialMediaListLoad] = useState(false)

    const loadSocialMedia = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/settings/api-get-settings.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            console.log(response.data)
            setSocialMediaListLoad(true)
            setFacebook(response.data[0].facebook_link)
            setInstagram(response.data[0].instagram_link)
            setHeadTags(response.data[0].head)

        })
    }

    const [socialMediaBtnLoading, setSocialMediaBtnLoading] = useState(false)
    let formData = new FormData();
    const saveSocialMedia = () => {
        setSocialMediaBtnLoading(true)
        formData.append('facebook', facebook)
        formData.append('instagram', instagram)
        formData.append('id', 1)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/settings/api-update-social-media.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            setSocialMediaBtnLoading(false)
        }).catch(function (response) {
            console.log(response);
        });
    }

    const saveHeadTag = () => {
        setSocialMediaBtnLoading(true)
        formData.append('tags', headTags)
        formData.append('id', 1)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/settings/api-update-head-tag.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            setSocialMediaBtnLoading(false)
        }).catch(function (response) {
            console.log(response);
        });
    }
    return (
        <div className="padding">
            <Card
                title="Sociala Media"
            >
                {socialMediaListLoad ?
                    <div className="padding">
                        <TextField fullWidth value={facebook} label="Facebook" variant="outlined" onChange={(e) => setFacebook(e.target.value)} />
                        <div className='d-mb'></div>
                        <TextField fullWidth value={instagram} label="Instagram" variant="outlined" onChange={(e) => setInstagram(e.target.value)} />
                        <div className='d-mb'></div>
                        <LoadingButton onClick={saveSocialMedia} fullWidth loading={socialMediaBtnLoading} variant="contained">
                            Spara
                        </LoadingButton>
                    </div>
                    : <Box sx={{ width: '100%' }}><LinearProgress color='secondary' /></Box>}
            </Card>

            <Card
                title="Head Tags"
            >
                {socialMediaListLoad ?
                    <div className="padding">
                        <TextField fullWidth value={headTags} label="Facebook" variant="outlined" onChange={(e) => setHeadTags(e.target.value)} multiline rows={4}/>
                        <div className='d-mb'></div>
                        <LoadingButton onClick={saveHeadTag} fullWidth loading={socialMediaBtnLoading} variant="contained">
                            Spara
                        </LoadingButton>
                    </div>
                    : <Box sx={{ width: '100%' }}><LinearProgress color='secondary' /></Box>}
            </Card>

        </div>
    )
}

export default Settings