import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../helper/Context';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useParams } from 'react-router-dom'
// Material
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// Icons
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// Components
import Card from '../../Components/Card';
const AccountView = ({ wh, authorized }) => {
    const navigate = useNavigate();

    const { id } = useParams()
    const [thisUser, setThisUser] = useState(null)

    // Header config
    const { setHeaderTitle, setHeaderLinkTo } = useContext(HeaderContext)
    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle(`Konto ${thisUser ? '(' + thisUser.user_email + ')' : null}`)
        document.title = `Hästgruppen | Konto  ${thisUser ? '(' + thisUser.user_email + ')' : null}`;
        setHeaderLinkTo('/account')
    }, [setHeaderTitle, setHeaderLinkTo, authorized, thisUser])

    useEffect(() => {
        if (authorized) {
            loadAccount()
        }
    }, [authorized])

    const [accountsListLoad, setAccountsListLoad] = useState(false)
    const [thisUserRole, setThisUserRole] = useState("")
    const loadAccount = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/users/api-get-users.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setThisUser(response.data.find(row => parseInt(row.user_ID) === parseInt(id)))
            setThisUserRole(response.data.find(row => parseInt(row.user_ID) === parseInt(id)).user_rule)
            setAccountsListLoad(true)
        })
    }

    const [btnLoading, setBtnLoading] = useState(false)

    let formData = new FormData();
    const updateRole = () => {
        setBtnLoading(true)
        formData.append('id', id)
        formData.append('role', thisUserRole)

        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_LINK}/api/users/api-change-user-role.php`,
            data: formData,
            headers: { 'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}` }
        }).then(function (response) {
            setBtnLoading(false)

        }).catch(function (response) {
            console.log(response);
        });
    }

    return (
        <div className="padding">

            <Card
                title="Roll"
            >
                {accountsListLoad ?
                    <div className="padding">
                        <FormControl fullWidth>
                            <InputLabel >Roll</InputLabel>
                            <Select
                                value={thisUserRole}
                                label="Roll"
                                onChange={(e) => setThisUserRole(e.target.value)}
                            >
                                <MenuItem value={"1"}>Användare</MenuItem>
                                <MenuItem value={"52"}>Admin</MenuItem>
                            </Select>
                        </FormControl>
                        <div className='d-mb'></div>
                        <LoadingButton onClick={updateRole} fullWidth loading={btnLoading} variant="contained">
                            Spara
                        </LoadingButton>
                    </div>
                    : <Box sx={{ width: '100%' }}><LinearProgress color='secondary' /></Box>}
            </Card>
        </div>
    )
}

export default AccountView