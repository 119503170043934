import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import { HeaderContext } from '../helper/Context';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
// Materail
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
// Icons
import HomeIcon from '@mui/icons-material/Home';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
const AsideList = () => {

    const navigate = useNavigate();
    const cookies = new Cookies();

    const { asideActive, setAsideActive } = useContext(HeaderContext)


    const [catelogOpen, setCatelogOpen] = useState(false);


    const [menu, setMeny] = useState(1);
    const handleChange = (event) => {
        setMeny(event.target.value);
    };

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    useEffect(() => {
        if (window.location.pathname.indexOf("/dashboard") !== -1) {
            setSelectedIndex(0)
            setMeny(1)
        } else if (window.location.pathname.indexOf("/timer") !== -1) {
            setSelectedIndex(1)
            setMeny(1)
        } else if (window.location.pathname.indexOf("/products") !== -1) {
            setSelectedIndex(2)
            setMeny(1)
        } else if (window.location.pathname.indexOf("/todo") !== -1) {
            setSelectedIndex(3)
            setMeny(1)
        } else if (window.location.pathname.indexOf("/errands") !== -1) {
            setSelectedIndex(4)
            setMeny(1)
        } else if (window.location.pathname.indexOf("/account") !== -1) {
            setSelectedIndex(10)
            setMeny(1)
        }


    }, [])


    const asideToggle = () => {
        setAsideActive(false)
    }




    const renderMenu = () => {
        if (menu === 1) {
            return (
                <Box sx={{
                    width: '100%',
                    maxWidth: 320,
                    "& .MuiList-root": {
                        padding: 0
                    }
                }} >
                    <List component="nav" aria-label="Dashboard" >
                        <Link to="/">
                            <ListItemButton
                                selected={selectedIndex === 0}
                                onClick={(event) => handleListItemClick(event, 0)}
                            >
                                <ListItemIcon>
                                    <HomeIcon style={{ color: "#fff" }}/>
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <Divider textAlign="left" style={{padding: '16px 0'}}><p style={{color: '#ffffff', fontSize: '12px'}}>Katelog</p></Divider>

                    <List component="nav" aria-label="Kategori">
                        <Link to="/category">

                            <ListItemButton
                                selected={selectedIndex === 1}
                                onClick={(event) => handleListItemClick(event, 1)}
                            >
                                <ListItemIcon>
                                    <StoreMallDirectoryIcon style={{ color: "#fff" }}/>
                                </ListItemIcon>
                                <ListItemText primary="Kategori" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <List component="nav" aria-label="Products">
                        <Link to="/announces">

                            <ListItemButton
                                selected={selectedIndex === 2}
                                onClick={(event) => handleListItemClick(event, 2)}
                            >
                                <ListItemIcon>
                                    <StoreMallDirectoryIcon style={{ color: "#fff" }}/>
                                </ListItemIcon>
                                <ListItemText primary="Annonser" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <Divider textAlign="left" style={{padding: '16px 0'}}><p style={{color: '#ffffff', fontSize: '12px'}}>Admin</p></Divider>

                    <List component="nav" aria-label="Account">
                        <Link to="/account">

                            <ListItemButton
                                selected={selectedIndex === 10}
                                onClick={(event) => handleListItemClick(event, 10)}
                            >
                                <ListItemIcon>
                                    <PersonIcon style={{ color: "#fff" }}/>
                                </ListItemIcon>
                                <ListItemText primary="Konto" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <List component="nav" aria-label="Account">
                        <Link to="/settings">

                            <ListItemButton
                                selected={selectedIndex === 11}
                                onClick={(event) => handleListItemClick(event, 11)}
                            >
                                <ListItemIcon>
                                    <SettingsIcon style={{ color: "#fff" }}/>
                                </ListItemIcon>
                                <ListItemText primary="Inställningar" />
                            </ListItemButton>
                        </Link>
                    </List>


                </Box>
            )
        } else if (menu === 2) {
            return (
                <Box sx={{ width: '100%', maxWidth: 320, }} >
                    <List component="nav" aria-label="Konfiguration">
                        <Link to="/konfiguration">
                            <ListItemButton
                                selected={selectedIndex === 8}
                                onClick={(event) => handleListItemClick(event, 8)}
                            >
                                <ListItemIcon>
                                    <PermDataSettingIcon style={{ color: "#fff" }}/>
                                </ListItemIcon>
                                <ListItemText primary="Konfiguration" />
                            </ListItemButton>
                        </Link>
                    </List>
                </Box>
            )
        }
    }
    return (
        <div>{renderMenu()}</div>
    )
}

export default AsideList