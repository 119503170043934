import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../helper/Context';
import { useNavigate } from 'react-router-dom'
// Tab
import ProductTable from './ProductTable'
const Product = ({ id, authorized }) => {

    const navigate = useNavigate();

    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)

    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle("Produkter")
        setHeaderLinkTo('')
        document.title = 'Prorec Webshop | Produkter';
    }, [setHeaderTitle, setHeaderLinkTo, authorized])


    return (
        <div>
            <ProductTable />
        </div>
    )
}

export default Product