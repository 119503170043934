import React, {useContext} from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import { HeaderContext } from '../helper/Context';
// Materail
import IconButton from '@mui/material/IconButton';
// Icons
import MenuIcon from '@mui/icons-material/Menu';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
const Header = ({ linkTo, title }) => {

    const {asideActive, setAsideActive} = useContext(HeaderContext)

    const asideToggle = () => {
        setAsideActive(!asideActive)
    }

    return (
        <div className='header'>
            <div className='header-left'>
                {linkTo ?
                    <Link to={linkTo}>
                        <IconButton aria-label="backword">
                            <NavigateBeforeIcon />
                        </IconButton>
                    </Link>
                    :
                    <IconButton aria-label="menu" onClick={asideToggle}>
                        <MenuIcon />
                    </IconButton>
                }


                <h1 id="headerTitle">{title}</h1>
            </div>
            <p id="HeaderLinkTo"></p>
        </div>
    )
}

export default Header