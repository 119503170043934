import { useState, useEffect } from 'react'
import { Routes, Route } from "react-router-dom";
import { HeaderContext } from './helper/Context';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import axios from 'axios';
// Layout
import Aside from "./Layout/Aside";
import Header from './Layout/Header';
import Main from "./Layout/Main";
import AppContainer from './Components/AppContainer';
// Pages
import Dashboard from './Pages/Dashboard/Dashboard';
import Login from './Pages/Login/Login';
import ResetPass from './Pages/Login/ResetPass';
import NewPass from './Pages/Login/NewPass';

// import Category from './pages/Category/Category';
import Category from './Pages/Catelog/Category/Category';
import CategoryView from './Pages/Catelog/Category/CategoryView';
import SubCategoryView from './Pages/Catelog/Category/SubCategoryView';
import SubCategoryAdd from './Pages/Catelog/Category/SubCategoryAdd';
import SubSubCategoryAdd from './Pages/Catelog/Category/SubSubCategoryAdd';

import Account from './Pages/Account/Account';
import AccountAdd from './Pages/Account/AccountAdd';
import AccountView from './Pages/Account/AccountView';
import Product from './Pages/Product/Product';
// import ProductView from './Pages/Product/ProductView';

import Products from './Pages/Products/Products'
import ProductView from './Pages/Products/ProductView';

import Settings from './Pages/Settings/Settings';

const App = () => {

    const navigate = useNavigate();
    let UUID;

    if (localStorage.getItem("User") !== null) {
        let userObject = localStorage.getItem('User');
        UUID = JSON.parse(userObject).UUID;
    }
    // Authrization
    const cookies = new Cookies();
    const token = cookies.get('jwt')
    const [authorized, setAuthorized] = useState(true)

    const [mobileSize, setMobileSize] = useState(false)
    const [tabletSize, setTabletSize] = useState(false)

    // const [asideActive, setAsideActive] = useState(false)

    const [headerTitle, setHeaderTitle] = useState('')
    const [headerLinkTo, setHeaderLinkTo] = useState('')
    const [asideActive, setAsideActive] = useState(false)


    const [wh, setWh] = useState(0)
    // Size detection
    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })
    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }
    useEffect(() => {
        window.addEventListener('resize', detectSize)
        if (token) {
            axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/auth/login_verify.php`, {
                params: {
                    token: token
                },
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
                }
            }).then((response) => {
                if (response.data.condition === false) {
                    setAuthorized(false)
                } else {
                    setAuthorized(true)
                }
            })
        } else {
            setAuthorized(false)
        }

        setWh(windowDimenion.winHeight)
        if (windowDimenion.winWidth <= 1280) {
            setTabletSize(true)
            setAsideActive(false)
        } else if (windowDimenion.winWidth <= 600) {
            setAsideActive(false)
            setTabletSize(false)
            setMobileSize(true)
        } else {
            setAsideActive(true)
            setTabletSize(false)
            setMobileSize(false)
        }
        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimenion, token, navigate])



    return (
        <HeaderContext.Provider value={{ headerTitle, setHeaderTitle, headerLinkTo, setHeaderLinkTo, asideActive, setAsideActive }}>
            <div className="container">
                {authorized ? <Aside active={asideActive} mobile={mobileSize} tablet={tabletSize} /> : null}
                <Main active={authorized ? asideActive : false}>
                    {authorized ? <Header title={headerTitle} linkTo={headerLinkTo} /> : null}
                    <AppContainer authorized={authorized}>
                        <Routes>
                            <Route path="/" element={<Dashboard id={UUID} authorized={authorized} />} exact></Route>
                            <Route path="/dashboard" element={<Dashboard id={UUID} authorized={authorized} />} exact></Route>

                            <Route path="/category" element={<Category wh={wh} id={UUID} authorized={authorized} />} exact></Route>
                            <Route path="/category/:iid" element={<CategoryView wh={wh} id={UUID} authorized={authorized} />} exact></Route>
                            <Route path="/category/sub/:iid/:siid" element={<SubCategoryView wh={wh} id={UUID} authorized={authorized} />} exact></Route>
                            <Route path="/category/sub/add/:iid" element={<SubCategoryAdd wh={wh} id={UUID} authorized={authorized} />} exact></Route>
                            <Route path="/category/sub/add/:iid/:siid" element={<SubSubCategoryAdd wh={wh} id={UUID} authorized={authorized} />} exact></Route>


                            <Route path="/account" element={<Account wh={wh} id={UUID} authorized={authorized} />} exact></Route>
                            <Route path="/account/add" element={<AccountAdd id={UUID} authorized={authorized} />} exact></Route>
                            <Route path="/account/edit/:id" element={<AccountView wh={wh} id={UUID} authorized={authorized} />} exact></Route>
                            
                            
                            <Route path="/product" element={<Product id={UUID} authorized={authorized} />} exact></Route>
                            <Route path="/product/view/:id" element={<ProductView id={UUID} authorized={authorized} />} exact></Route>

                            <Route path="/announces" element={<Products wh={wh} id={UUID} authorized={authorized} />} exact></Route>
                            <Route path="/announces/:iid" element={<ProductView wh={wh} id={UUID} authorized={authorized} />} exact></Route>


                            <Route path="/settings" element={<Settings wh={wh} id={UUID} authorized={authorized} />} exact></Route>



                            <Route path="/login" element={<Login />} exact></Route>
                            <Route path="/reset-password" element={<ResetPass />} exact></Route>
                            <Route path="/change-password/:vkey" element={<NewPass />} exact></Route>

                        </Routes>
                    </AppContainer>

                </Main>
            </div>
        </HeaderContext.Provider>
    )
}

export default App