import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../helper/Context';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

// Material
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import LinearProgress from '@mui/material/LinearProgress';

// Icons
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Products = ({ wh, authorized }) => {

    const navigate = useNavigate();


    const columns = [
        {
            field: 'title',
            headerName: 'Titel',
            width: 350,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (cellValues) => {
                if (cellValues.row.status === 1 || cellValues.row.status === '1') {
                    return (
                        <div>
                            <p>Accepterad</p>
                        </div>
                    )
                } else if (cellValues.row.status === 0 || cellValues.row.status === '0') {
                    return (
                        <div>
                            <p>Obehandlad</p>
                        </div>
                    )
                } else if (cellValues.row.status === 2 || cellValues.row.status === '2') {
                    return (
                        <div>
                            <p>Avvisade</p>
                        </div>
                    )
                }

            },
            width: 150,
            editable: false,
        },
        {
            field: 'cat',
            headerName: 'Kategori',
            width: 150,
            editable: true,
        },
        {
            field: 'handle',
            renderCell: (cellValues) => {
                return (
                    <div>
                        <IconButton
                            aria-label="Navigate"
                            onClick={(e) => {
                                navigateRow(e, cellValues)
                            }}>
                            <NavigateNextIcon />
                        </IconButton>
                    </div>

                )
            },
            headerName: '',
            type: 'number',
            flex: 1,
            editable: false,
        }
    ];



    const [filterToggle, setFilterToggle] = useState(false)
    // Header config
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)


    const [search, setSearch] = useState("")
    const [products, setProducts] = useState([])
    const [productLoad, setProductLoad] = useState(false)


    useEffect(() => {
        if (search) {
            setRow(products.filter(row => row.title.toLowerCase().indexOf(search.toLowerCase()) > -1))
        }
    }, [search])


    const [rows, setRow] = useState([])


    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle("Annonser")
        document.title = 'Hästgruppen | Annonser';
        setHeaderLinkTo('')
    }, [setHeaderTitle, setHeaderLinkTo, authorized])


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            setProducts(response.data)
            setRow(response.data)
            setProductLoad(true)
        })
    }, [])


    const navigateRow = (e, c) => {
        let name = c.row.link_name
        navigate(`/announces/${name}`)
    }


    return (
        <Box sx={{ height: wh - 64, width: '100%', display: 'flex', flexDirection: 'column' }}>
            {productLoad ?
                <>
                    <Box className='fading'>

                        <Box sx={{ display: 'flex', alignItems: 'flex-end', borderBottom: '1px solid #f2f2f2', pl: 2, pr: 2 }}>
                            <input type="text" onChange={(e) => setSearch(e.target.value)} placeholder='sök genom annons titel...' style={{ height: 48, width: '100%', fontSize: 18, outline: 'none', placeholder: 'red', border: 'none' }} />
                            <IconButton onClick={() => setFilterToggle(!filterToggle)}>
                                <FilterListIcon />
                            </IconButton>
                        </Box>

                        <Collapse in={filterToggle} sx={{ borderBottom: '1px solid #f2f2f2' }}>

                        </Collapse>
                    </Box>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        disableSelectionOnClick
                        disableColumnMenu
                        disableCellFocusOutline
                        getRowId={(row) => row.product_ID}
                        sx={{
                            flexFlow: 1,
                            border: 'none',
                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                display: "none"
                            }
                        }}
                        className='fading'
                    />
                </>
                : <Box sx={{ width: '100%' }}><LinearProgress color='secondary' /></Box>}

        </Box>
    )
}

export default Products