import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { visuallyHidden } from '@mui/utils';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'title',
        numeric: false,
        disablePadding: false,
        label: 'Titel',
    },
    {
        id: 'category',
        numeric: false,
        disablePadding: false,
        label: 'Kategori',
    },
    {
        id: 'handle',
        numeric: true,
        disablePadding: false,
        label: '',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({ onSearch }) => {
    return (
        <Toolbar>
            <TextField sx={{ flex: '1 1 100%' }} label="Sök" variant="standard" onChange={onSearch} />
        </Toolbar>
    );
};


const ProductTable = () => {

    const navigate = useNavigate();

    const [serach, setSearch] = useState("")
    const [load, setLoad] = useState(false)
    const [rows, setRow] = useState([])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-get-product.php`, {
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            if (serach.length > 0) {
                setRow(response.data.filter(row => row.title.toLowerCase().indexOf(serach.toLowerCase()) > -1))
            } else {
                setRow(response.data)
                setLoad(true)
            }
        })
    }, [serach])

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const editRow = (id) => {
        navigate("/product/view/" + id)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    // Delete Stores
    const [deleteDialop, setdeleteDialop] = useState(false);
    const dialogClose = () => {
        setdeleteDialop(false);
    };
    const [selectedID, setSelectedID] = useState(0);
    const handleDeleteAcc = (id) => {
        setdeleteDialop(true);
        setSelectedID(id)
    }

    const confirmDeleteAcc = (id) => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/product/api-delete-product.php`, {
            params: {
                id: id,
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            if (response.status === 200) {
                setRow(rows.filter(row => row.product_ID !== id))
                setdeleteDialop(false)
            }
        })
    }

    return (
        <Box sx={{ width: '100%' }}>

            {load ?

                <Paper sx={{ width: '100%', mb: 2 }} className="fading">
                    <EnhancedTableToolbar onSearch={(e) => setSearch(e.target.value)} />

                    <TableContainer>
                        <Table
                            sx={{ minWidth: 500 }}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />


                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >

                                                <TableCell >{row.title}</TableCell>
                                                <TableCell >{row.cat}</TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Radigera">
                                                        <IconButton aria-label="reset-password" size="small" onClick={() => editRow(row.product_ID)}>
                                                            <RemoveRedEyeIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Radera">
                                                        <IconButton aria-label="delete" size="small" onClick={() => handleDeleteAcc(row.product_ID)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (54) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100, 250]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                    <Dialog open={deleteDialop} onClose={dialogClose} >
                        <DialogTitle id="alert-dialog-title">
                            Är du sökert du vill att ta bort produkten?
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Ta bort produkten
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={dialogClose} color="error">Nej</Button>
                            <Button onClick={() => confirmDeleteAcc(selectedID)} color="secondary" autoFocus>Ja</Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
                :
                <Box sx={{ width: '100%' }}>
                    <LinearProgress color='secondary' />
                </Box>
            }
        </Box>
    )
}

export default ProductTable