import React, { useEffect, useContext, useState } from 'react'
import { HeaderContext } from '../../../helper/Context';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useParams } from 'react-router-dom'

// Material
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const SubCategoryView = ({ wh, authorized }) => {
    const navigate = useNavigate();

    const { iid, siid } = useParams()
    // Header config
    const { setHeaderTitle } = useContext(HeaderContext)
    const { setHeaderLinkTo } = useContext(HeaderContext)
    useEffect(() => {
        if (!authorized) {
            navigate('/login')
        }
        setHeaderTitle(`Underkategori ...`)
        document.title = `Hästgruppen | Underkategori ...`;
        setHeaderLinkTo(`/category/${iid}`)
        loadSubCategory()
        loadSubSubCategory()
    }, [setHeaderTitle, setHeaderLinkTo, authorized])

    const columns = [
        {
            field: 'name',
            headerName: 'Titel',
            width: 450,
            editable: false,
        },
        {
            field: 'handle',
            renderCell: (cellValues) => {
                return (
                    <div>
                        <IconButton
                            aria-label="edit"
                            onClick={(e) => {
                                editRow(e, cellValues)
                            }}>
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                                deleteRow(e, cellValues)
                            }}>
                            <DeleteIcon />
                        </IconButton>
                        {/* <IconButton
                            aria-label="Navigate"
                            onClick={(e) => {
                                navigateRow(e, cellValues)
                            }}>
                            <NavigateNextIcon />
                        </IconButton> */}
                    </div>

                )
            },
            headerName: '',
            type: 'number',
            width: 150,
            editable: false,
        }
    ];

    const [rows, setRows] = useState([])

    const loadSubCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_category.php`, {
            params: {
                id: iid
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            const thisRow = response.data.filter(row => row.sub_category_ID === parseInt(siid) || row.sub_category_ID === siid)
            setHeaderTitle(`Underkategori ${thisRow[0].name}`)
            document.title = `Hästgruppen | Underkategori ${thisRow[0].name}`;
        })
    }

    const loadSubSubCategory = () => {
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/category/api_get_sub_sub_category.php`, {
            params: {
                id: iid,
                sid: siid
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            console.log(response.data)
            setRows(response.data)
        })
    }


    const deleteRow = (e, c) => {
        let id = c.id
    }
    const editRow = (e, c) => {
        let id = c.id
    }
    const navigateRow = (e, c) => {
        let id = c.id
        navigate(`/category/${id}`)
    }

    const add = () => {
        navigate(`/category/sub/add/${iid}/${siid}`)
    }

    return (
        <Box sx={{ height: wh - 64, width: '100%', display: 'flex', flexDirection: 'column' }}>

            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableSelectionOnClick
                disableColumnMenu
                disableCellFocusOutline
                getRowId={(row) => row.sub_sub_category_ID}
                sx={{
                    flexFlow: 1,
                    border: 'none',
                    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                        display: "none"
                    }
                }}
            />
            <Fab color={'primary'} onClick={add} className='float-action-button'>
                <AddIcon />
            </Fab>

        </Box>
    )
}

export default SubCategoryView