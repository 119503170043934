import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import './style.css'
const Card = ({ children, title, description, load, footer, actionButton, xe, headerAction }) => {
    return (
        <div   className={xe ? 'card xe' : 'card'}>

            <div className='card-header'>
                <h3>{title}</h3>
                {headerAction ? headerAction : null}
            </div>
            {load ? <Box sx={{ width: '100%' }}><LinearProgress color='secondary' /></Box> : null}
            
            <div >
                {children}
            </div>
            {footer ? <div className='card-footer'><div></div><div>{actionButton}</div></div> : null}
        </div>
    )
}

export default Card